body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

.topo {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
}

.solicit-title {
  color: #1351b4;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: -18px;
}

.btn-bl {
  background-color: #1351b4;
  padding: 20px 35px;
  color: #fff;
  font-size: 18px;
  border-radius: 50px;
  border: 15px solid #ededed;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}

.btn-bl:hover {
  background-color: #136cb4;
}

.btn-bl:active {
  background-color: #136cb4;
}

.btn-pd {
  background-color: #268744;
  padding: 20px 35px;
  color: #fff;
  font-size: 18px;
  border-radius: 50px;
  border: 15px solid #ededed;
  font-weight: 600;
  cursor: pointer;
}

.btn-pd:hover {
  background-color: #2ab956;
}

.btn-pd:active {
  background-color: #2ab956;
}

.cab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 40px;
}

.cab h4 {
  font-size: 18px;
  font-weight: 400;
  color: #888;
}

.mod {
  color: #999;
  text-align: center;
}

.footer {
  background-color: #071d41;
  text-align: center;
}

.footer div {
  padding: 20px;
}

.subfooter {
  background-color: #04132a;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.page {
  padding-bottom: 50px;
}

.pay {
  padding: 40px 20px;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

.protocol {
  margin: 20px 0;
}

.pay h3 {
  color: #1351b4;
  font-size: 18px;
}

@media (max-width: 900px) {
  .cab {
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
  }
}
